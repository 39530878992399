import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next';
import MetaPages from './../../components/MetaPages.jsx';
import { useParams } from 'react-router-dom';
import './tpagina.scss';
import { getPageAPI } from "../../data/tpagina.jsx";
import { Base64 } from 'js-base64';
import { getParameterByAliasAPI } from "../../data/parameter.jsx";

import ReactGA from 'react-ga4';

const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT;

const TPagina = () => {
    const { pagina } = useParams()
    const [loading, setLoading] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false)
    const {i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);
    const thisUrlPage = window.location.href;
    const [page, setPage] = useState("")
    const [nomeCliente, setNomeCliente] = useState("")

    async function getNomeCliente(){
        const response = await getParameterByAliasAPI("nomecliente")
        setNomeCliente(response.varchar)
    }

    async function getPage(){
        const response = await getPageAPI(pagina, "paginas", currentLanguage)
        setPage(response)
    }

    useEffect(() =>{
        const getData = async () => {
            if(!loading){
                setLoading(true)
                try {
                    await Promise.all([
                        getPage(),
                        getNomeCliente()
                    ]);
                } catch (error) {
                    console.error("Failed to fetch data:", error);
                } finally {
                    setLoading(false);
                    setDataLoaded(true)
                }
            }
        }
        getData()
    }, [pagina])

    useEffect(() => {
        if(page){
            ReactGA.send({
                hitType: "pageview",
                page: thisUrlPage,
                title: page.pageTitle
            })
        }
    }, [page])

    useEffect(() => {
        if(!loading && !page && dataLoaded === true){
            return window.location.replace(VITE_PUBLIC_ROOT+"/"+currentLanguage+"/not-found")
        }
    }, [loading])

    return (
        <div>   
            {page && nomeCliente &&(
                <MetaPages newLang={language} pageTitle={nomeCliente+" - "+page.pageTitle} pageMetaSummary={page.pageMetaSummary} pageMetaAuthor={page.pageMetaAuthor} pageMetaTags={page.pageMetaTags} robots="index, follow" ogtype="website" ogurl={thisUrlPage} pageSeoTitle={page.pageSeoTitle} pageSeoDescription={page.pageSeoDescription} pageSeoImage={page.pageSeoImage} pageSeoImageAlt={page.pageSeoImageAlt} />
            )}
            <section className="pagecontent">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <div className="text-wrapper">
                            {/* <h5 className="text-grey"><strong>Pagina</strong></h5>    */}
                            {page &&(
                                <>
                                    <h3 className="mbr-section-title mbr-fonts-style mb-3 display-5" style={{fontWeight: 400}}><strong>{page.pageTitle}</strong></h3>
                                    <p className="mbr-text mbr-fonts-style display-7" dangerouslySetInnerHTML={{__html: Base64.decode(page.pageContent)}}></p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            </section>
        </div>
    )
}

export default TPagina