import React, { useEffect, useState } from "react"
import { useTranslation } from 'react-i18next';
import MetaPages from './../../components/MetaPages.jsx';
import { getEmpresaPage } from "../../data/empresa.jsx";
import { Base64 } from 'js-base64';
import { getParameterByAliasAPI } from "../../data/parameter.jsx";
import { getMultimediaByAliasAndLanguage } from "../../data/multimedia.jsx";

import ReactGA from 'react-ga4';

import './pagina.scss';


const Pagina = () => {
    const BaseImgPath = import.meta.env.VITE_BASE_IMAGE_PATH;
    const [loading, setLoading] = useState(false)
    const {i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);
    const thisUrlPage = window.location.href;
    const [page, setPage] = useState("")

    const [nomeCliente, setNomeCliente] = useState("")

    const [ image, setImage] = useState("")

    async function getImage(){
        const response = await getMultimediaByAliasAndLanguage("empresa1", currentLanguage)
        setImage(response)
    }

    async function getNomeCliente(){
        const response = await getParameterByAliasAPI("nomecliente")
        setNomeCliente(response.varchar)
    }

    async function getEmpresaPages(){
        const response = await getEmpresaPage(currentLanguage)
        setPage(response)
    }

    useEffect(() => {
        const getData = async () => {
            if(!loading){
                setLoading(true)
                try {
                    await Promise.all([
                        getNomeCliente(),
                        getImage(),
                        getEmpresaPages()
                    ]);
                } catch (error) {
                    console.error("Failed to fetch data:", error);
                } finally {
                    setLoading(false);
                }
            }
        }
        getData()
    }, [])

    useEffect(() => {
        if(page){
            ReactGA.send({
                hitType: "pageview",
                page: thisUrlPage,
                title: page.pageTitle
            })
        }
    }, [page])

    return (
        <>
            {page && nomeCliente &&(
                <MetaPages newLang={language} pageTitle={nomeCliente +" - "+page.pageTitle} pageMetaSummary={page.pageMetaSummary} pageMetaAuthor={page.pageMetaAuthor} pageMetaTags={page.pageMetaTags} robots="index, follow" ogtype="website" ogurl={thisUrlPage} pageSeoTitle={page.pageSeoTitle} pageSeoDescription={page.pageSeoDescription} pageSeoImage={page.pageSeoImage} pageSeoImageAlt={page.pageSeoImageAlt} />
            )}
            <section className="pagecontent">
            <div className="container">
                <div className="row align-items-center">
                    {image && (
                        <div className="col-12 col-lg-5 comimagem" style={{backgroundImage : 'url('+BaseImgPath+image.path+')',backgroundRepeat: 'no-repeat',
                            backgroundAttachment: 'fixed',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover'}}>
                            {/* <div className="image-wrapper">
                                <img src={BaseImgPath+image.path} alt="zoomtyre" className="img-responsive" style={{width: "100%"}}/>
                            </div> */}
                        </div>
                    )}
                    
                    <div className="col-12 col-lg">
                        <div className="text-wrapper">
                            {/* <h5 className="text-grey"><strong>Empresa</strong></h5>    */}
                            {page &&(
                                <>
                                    <h3 className="mbr-section-title mbr-fonts-style mb-3 display-5" style={{fontWeight: 400}}><strong>{page.pageTitle}</strong></h3>
                                    <p className="mbr-text mbr-fonts-style display-7" dangerouslySetInnerHTML={{__html: Base64.decode(page.pageContent)}}></p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            </section>
        </>
    )
}

export default Pagina

/*
<section className="titlepage">
        <div className="container">
            <div className="row justify-content-center">
                <div className="title col-md-12 col-lg-10">
                    <h3 className="mbr-section-title mbr-fonts-style align-center mb-4 display-2" style={{fontWeight: 400}}>
                        <strong>Empresa</strong></h3>    
                </div>
            </div>
        </div>
        </section>
*/