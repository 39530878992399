import React, { useEffect, useState, useRef } from "react"
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { BsFillEnvelopeAtFill } from "react-icons/bs";
import { submitContact, getPageContactoAPI } from "../../data/contact";
import MetaPages from "../../components/MetaPages";
import { getParameterByAliasAPI } from "../../data/parameter";
import { getMultimediaByAliasAndLanguage } from "../../data/multimedia";
import { getPagesByCategoryAndLanguage } from "../../data/pages";
import { Base64 } from "js-base64";

import Captcha from "../../components/Captcha";
import ReCAPTCHA from "react-google-recaptcha";
import ReactGA from 'react-ga4';

const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT;
const BaseImgPath = import.meta.env.VITE_BASE_IMAGE_PATH;

import './contatos.scss'

const Contatos = () => {
    const reCaptchaRef = useRef()
    const [recaptchaType, setRecaptchaType] = useState(0)
    const [loadingPage, setLoadingPage] = useState(false)
    const [loading, setLoading] = useState(false);
    const { t, i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);
    const [data, setData] = useState({ name : "", email : "", phone : "", message : "" })
    const [page, setPage] = useState("")

    const [recaptchaSiteKey, setRecaptchaSiteKey] = useState('')

    const [errorCaptcha, setErrorCaptcha] = useState(false)

    const [captchaValid, setCaptchaValid] = useState(false)

    const thisUrlPage = window.location.href;

    const [pages, setPages] = useState([])

    const [image, setImage] = useState("")

    const [nomeCliente, setNomeCliente] = useState("")

    async function getRecaptchaSiteKey(){
        const response = await getParameterByAliasAPI("recaptchasitekey")
        setRecaptchaSiteKey(response.varchar)
    }

    function handleValidateCaptcha(data){
        setCaptchaValid(data)
    }

    function handleSetErrorCaptcha(data){
        setErrorCaptcha(data)
    }

    async function getNomeCliente(){
        const response = await getParameterByAliasAPI("nomecliente")
        setNomeCliente(response.varchar)
    }

    async function getRecaptchaType(){
        const response = await getParameterByAliasAPI("recaptchatype")
        setRecaptchaType(response.int)
    }

    async function getImage(){
        const response = await getMultimediaByAliasAndLanguage("contactos", currentLanguage)
        setImage(response)
    }

    async function getPage(){
        const response = await getPageContactoAPI(currentLanguage)
        setPage(response)
    }

    async function getPages(){
        const response = await getPagesByCategoryAndLanguage("contactos",currentLanguage)
        setPages(response)
    }

    useEffect(() => {
        const getData = async () => {
            if (!loadingPage) {
                setLoadingPage(true);
                try {
                    await Promise.all([
                        getNomeCliente(),
                        getPage(),
                        getImage(),
                        getPages(),
                        getRecaptchaType(),
                        getRecaptchaSiteKey()
                    ]);
                } catch (error) {
                    console.error("Failed to fetch data:", error);
                } finally {
                    setLoadingPage(false);
                }
            }
        };
        getData();
    }, [])

    async function handleSubmitContact(e){
        e.preventDefault()
        if(!loading){
            if((recaptchaType === 0 && captchaValid) || recaptchaType === 1){
                try {
                    setLoading(true)
                    let token
                    if(recaptchaType === 1){
                        token = await reCaptchaRef.current.executeAsync();
                        reCaptchaRef.current.reset();
                    }
                    await submitContact(data.name, data.email, data.phone, data.message, "contacto", currentLanguage, token)
                    setLoading(false)
                    window.location.replace(VITE_PUBLIC_ROOT+'/'+currentLanguage+'/obrigado/obrigado-contacto');
                } catch (error) {
                    setLoading(false)
                }
            }else if(recaptchaType === 0 && !captchaValid){
                setErrorCaptcha(true)
            }
        }
    }

    useEffect(() => {
        if(page){
            ReactGA.send({
                hitType: "pageview",
                page: thisUrlPage,
                title: page.pageTitle
            })
        }
    }, [page])

    return (
        <>
            {page && nomeCliente &&(
                <MetaPages newLang={language} pageTitle={nomeCliente+" - "+page.pageTitle} pageMetaSummary={page.pageMetaSummary} pageMetaAuthor={page.pageMetaAuthor} pageMetaTags={page.pageMetaTags} robots="index, follow" ogtype="website" ogurl={thisUrlPage} pageSeoTitle={page.pageSeoTitle} pageSeoDescription={page.pageSeoDescription} pageSeoImage={page.pageSeoImage} pageSeoImageAlt={page.pageSeoImageAlt} />
            )}
            <section className="top-imagem">
                <div className="container-fluid">
                    {image &&(
                        <div className="row justify-content-center img-top-content" style={{backgroundImage : 'url('+BaseImgPath+image.path+')'}}>
                            <div className="col-12 this-center-all">
                                <div className="wrapper-content ">
                                    <h2 className="display-5-b text-white">
                                        {page &&(
                                            page.pageTitle
                                        )}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>
            <section>
                <div className="contact_info">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="contact_info_container d-flex flex-lg-row flex-column justify-content-between align-items-between">
                                    <div className="contact_info_item d-flex flex-row align-items-center justify-content-start">
                                        <div className="contact_info_image"><i className="bi bi-phone-vibrate-fill"></i></div>
                                        <div className="contact_info_content">
                                            <div className="contact_info_title">{t(currentLanguage+'_telefone')}</div>
                                            {pages && pages.length > 0 && pages.map((page) => {
                                                if(page.pageSeoSlug === "contacto-telefone"){
                                                    return <div key={page.pageSeoSlug} className="contact_info_text" dangerouslySetInnerHTML={{__html: Base64.decode(page.pageContent)}}></div>
                                                }
                                            })}
                                        </div>
                                    </div>

                                    <div className="contact_info_item d-flex flex-row align-items-center justify-content-start">
                                        <div className="contact_info_image"><BsFillEnvelopeAtFill /></div>
                                        <div className="contact_info_content">
                                            <div className="contact_info_title">{t(currentLanguage+'_email')}</div>
                                            {pages && pages.length > 0 && pages.map((page) => {
                                                if(page.pageSeoSlug === "contacto-email"){
                                                    return <div key={page.pageSeoSlug} className="contact_info_text" dangerouslySetInnerHTML={{__html: Base64.decode(page.pageContent)}}></div>
                                                }
                                            })}
                                        </div>
                                    </div>


                                    <div className="contact_info_item d-flex flex-row align-items-center justify-content-start">
                                        <div className="contact_info_image"><i className="bi bi-geo-alt-fill"></i></div>
                                        <div className="contact_info_content">
                                            <div className="contact_info_title">{t(currentLanguage+'_localizacao')}</div>
                                            {pages && pages.length > 0 && pages.map((page) => {
                                                if(page.pageSeoSlug === "contacto-localizacao"){
                                                    return <div key={page.pageSeoSlug} className="contact_info_text" dangerouslySetInnerHTML={{__html: Base64.decode(page.pageContent)}}></div>
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="contact_form">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-10 offset-lg-1">
                                <div className="contact_form_container">
                                    <div className="contact_form_title">{t(currentLanguage+'_entre-em-contacto')}</div>

                                    <form id="contact_form" onSubmit={(e) => handleSubmitContact(e)}>
                                        <div className="contact_form_inputs d-flex flex-md-row flex-column justify-content-between align-items-between">
                                            <input value={data.name} onChange={(e) => setData({...data, name: e.target.value})} type="text" id="contact_form_name" className="contact_form_name input_field" placeholder={t(currentLanguage+'_nome')} required="required" data-error="Nome é obrigatório."/>
                                            <input value={data.email} onChange={(e) => setData({...data, email: e.target.value})} type="email" id="contact_form_email" className="contact_form_email input_field" placeholder={t(currentLanguage+'_email')} required="required" data-error="Email é obrigatório."/>
                                            <input value={data.phone} onChange={(e) => setData({...data, phone: e.target.value})} type="tel"  id="contact_form_phone" className="contact_form_phone input_field" placeholder={t(currentLanguage+'_telefone')}/>
                                        </div>
                                        <div className="contact_form_text">
                                            <textarea value={data.message} onChange={(e) => setData({...data, message: e.target.value})} id="contact_form_message" className="text_field contact_form_message" name="message" rows="4" placeholder={t(currentLanguage+'_comentario')} required="required" data-error="Por favor, escreva uma mensagem"></textarea>
                                        </div>
                                        <div className="col-lg-12 col-md-12 col-sm-12 mb-3 input-group">
                                            <input type="checkbox" className="form-check-input" id="politica" required/>
                                            <label className="form-check-label" for="politica">
                                                <span className="text-black">
                                                    <Link to={VITE_PUBLIC_ROOT+"/"+currentLanguage+"/pages/politica-privacidade"}>
                                                        {t(currentLanguage+'_aceito-politica-privacidade')}
                                                    </Link>
                                                </span>
                                            </label>
                                        </div>
                                        {recaptchaType === 0 &&(
                                            <>
                                                {!captchaValid &&(
                                                <Captcha handleValidateCaptcha={handleValidateCaptcha} errorCaptcha={errorCaptcha} handleSetErrorCaptcha={handleSetErrorCaptcha}/>
                                                )}
                                                {captchaValid &&(
                                                    <span className="text-success">
                                                        {t(currentLanguage+"_capcha-success-message")}
                                                    </span>
                                                )}
                                            </>
                                        )}
                                        {recaptchaType === 1 && recaptchaSiteKey &&(
                                            <ReCAPTCHA
                                                ref={reCaptchaRef}
                                                sitekey={recaptchaSiteKey}
                                                badge="bottomright"
                                                size="invisible"
                                            />
                                        )}
                                        <div className="contact_form_button">
                                            <button type="submit" className="btn btn-secondary btn-black contact_submit_button">
                                                {loading ? (
                                                    <div className="spinner-border" role="status">
                                                        <span className="visually-hidden">{t(currentLanguage+'_aguarde')}</span>
                                                    </div> 
                                                ):(
                                                    <span>{t(currentLanguage+'_submeter-contacto')}</span>
                                                )}
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>        
        </>
    )
}

export default Contatos