import { useEffect, useState, useContext } from "react"
import { useTranslation } from 'react-i18next';
import { Link, useParams } from "react-router-dom";
import NavAreaPrivada from "../../components/NavAreaPrivada";
import { getParameterByAliasAPI } from "../../data/parameter";
import { getContaCorrentePHC, getTotaisContaCorrentePHC, getContaCorrentePendentePHC } from "../../data/apiPHC";
import { getPageAPI } from "../../data/tpagina";
import { formatarData } from "../../services/utils";

import MetaPages from "../../components/MetaPages";
import LoadingPage from "../../components/LoadingPage";

import { AuthContext } from "../../contexts/authContext";

import ReactGA from 'react-ga4';

const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT;

import './cc.scss'


const ContaCorrente = () => {
    const { userData } = useContext(AuthContext)
    const { menu } = useParams()
    const [loading, setLoading] = useState(false);
    const { t, i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);

    const [nomeCliente, setNomeCliente] = useState("")
    const [page, setPage] = useState("")
    const thisUrlPage = window.location.href;

    const [casasDecimais, setCasasDecimais] = useState(2)

    const [contaCorrente, setContaCorrente] = useState([])
    const [totais, setTotais] = useState([])

    const [contaCorrentePendentes, setContaCorrentePendentes] = useState([])
    // const [totaisPendentes, setTotaisPendentes] = useState([])

    const [selectedCC, setSelectedCC] = useState("cc")

    const handleChangeSelectedCC = (data) => {
        setSelectedCC(data)
    }

    async function getNomeCliente(){
        const response = await getParameterByAliasAPI("nomecliente")
        setNomeCliente(response.varchar)
    }

    async function getPage(){
        const response = await getPageAPI("conta-corrente", "conta-corrente", currentLanguage)
        setPage(response)
    }

    const getCasasDecimais = async () => {
        const response = await getParameterByAliasAPI("apiphccasasdecimais")
        setCasasDecimais(response.int)
    }

    const getContaCorrente = async () => {
        const response = await getContaCorrentePHC(userData.no)
        setContaCorrente(response)
    }

    const getTotaisContaCorrente = async () => {
        const response = await getTotaisContaCorrentePHC(userData.no)
        setTotais(response)
    }

    const getContaCorrentePendentes = async () => {
        const response = await getContaCorrentePendentePHC(userData.no)
        setContaCorrentePendentes(response)
    }

    // const getTotaisContaCorrentePendentes = async () => {
    //     const response = await getTotaisContaCorrentePendentePHC(userData.no)
    //     setTotaisPendentes(response)
    // }

    useEffect(() => {
        const getData = async () => {
            if (!loading) {
                setLoading(true);
                try {
                    await Promise.all([
                        getCasasDecimais(),
                        getContaCorrente(),
                        getTotaisContaCorrente(),
                        getContaCorrentePendentes(),
                        getNomeCliente(),
                        getPage()
                        // getTotaisContaCorrentePendentes()
                    ]);
                } catch (error) {
                    console.error("Failed to fetch data:", error);
                } finally {
                    setLoading(false);
                }
            }
        };
        getData();
        if(menu){
            handleChangeSelectedCC(menu)
        }
    }, []);

    useEffect(() => {
        if(page){
            ReactGA.send({
                hitType: "pageview",
                page: thisUrlPage,
                title: page.pageTitle
            })
        }
    }, [page])

    return (
        <>
            {page && nomeCliente &&(
                <MetaPages newLang={language} pageTitle={nomeCliente+" - "+page.pageTitle} pageMetaSummary={page.pageMetaSummary} pageMetaAuthor={page.pageMetaAuthor} pageMetaTags={page.pageMetaTags} robots="noindex, nofollow" ogtype="website" ogurl={thisUrlPage} pageSeoTitle={page.pageSeoTitle} pageSeoDescription={page.pageSeoDescription} pageSeoImage={page.pageSeoImage} pageSeoImageAlt={page.pageSeoImageAlt} />
            )}
            <section class="reservada">
                <div class="container-fluid">
                    <div class="row">
                        <NavAreaPrivada/>
                        <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4 py-4">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={VITE_PUBLIC_ROOT+"/"+currentLanguage+"/dashboard"}>
                                            {t(currentLanguage+'_area-privada')}
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        {t(currentLanguage+'_cc-title')}
                                    </li>
                                </ol>
                            </nav>
                            <h1 class="h2">{t(currentLanguage+'_cc-title')}</h1>
                            <p>{t(currentLanguage+'_cc-description')}</p>
                            <div class="btn-group mb-4" role="group" aria-label="Selected CC">
                                <button type="button" onClick={() => handleChangeSelectedCC("cc")} class={`btn ${selectedCC === "cc" ? "btn-primary" : "btn-secondary"}`}>
                                    Conta Corrente
                                </button>
                                <button type="button" onClick={() => handleChangeSelectedCC("ccp")} class={`btn ${selectedCC === "ccp" ? "btn-primary" : "btn-secondary"}`}>
                                    CC Pendentes
                                </button>
                            </div>
                            <div class="row">
                                <div class="col-12 col-xl-12 mb-4 mb-lg-0">
                                    {loading ? (
                                        <LoadingPage/>
                                    ):(
                                        <div class="card">
                                            <h5 class="card-header">{t(currentLanguage+'_cc-table-title')}&nbsp;{selectedCC === "cc" ? "": t(currentLanguage+"_cc-pendentes")}</h5>
                                            <div class="card-body">
                                                <div class="table-responsive" id="tabresponsive">
                                                <table class="dcf-table dcf-table-responsive dcf-table-bordered dcf-table-striped dcf-w-100%">
                                                    {((selectedCC === "cc" && contaCorrente.length > 0) || (selectedCC === "ccp" && contaCorrentePendentes.length > 0)) &&(
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">{t(currentLanguage+'_cc-data')}</th>
                                                                <th scope="col">{t(currentLanguage+'_cc-vencimento')}</th>
                                                                <th scope="col">{t(currentLanguage+'_cc-documento')}</th>
                                                                <th scope="col">{t(currentLanguage+'_cc-n-documento')}</th>
                                                                <th class="dcf-txt-right" scope="col">{selectedCC === "cc" ? t(currentLanguage+'_cc-debito') : t(currentLanguage+'_cc-idade')}</th>
                                                                <th class="dcf-txt-right" scope="col">{selectedCC === "cc" ? t(currentLanguage+'_cc-credito') : t(currentLanguage+'_cc-valor')}</th>
                                                                <th class="dcf-txt-right" scope="col">{t(currentLanguage+'_cc-saldo')}</th>
                                                                <th scope="col">{t(currentLanguage+'_cc-incl-doc')}</th>
                                                            </tr>
                                                        </thead>
                                                    )}
                                                    {selectedCC === "cc" ? (
                                                        <tbody>
                                                            {contaCorrente.map((cc, index) => {
                                                                return (
                                                                    <tr key={"contaCorrenteRow"+index}>
                                                                        <th scope="row">{formatarData(cc.data_doc)}</th>
                                                                        <td data-label={t(currentLanguage+'_cc-vencimento')}>{formatarData(cc.data_venc)}</td>
                                                                        <td data-label={t(currentLanguage+'_cc-documento')}>{cc.descricao_doc}</td>
                                                                        <td data-label={t(currentLanguage+'_cc-n-documento')}>{cc.num_doc}</td>
                                                                        <td class="dcf-txt-right" data-label={t(currentLanguage+'_cc-debito')}>{new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(cc.debito))}</td>
                                                                        <td class="dcf-txt-right" data-label={t(currentLanguage+'_cc-credito')}>{new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(cc.credito))}</td>
                                                                        <td class="dcf-txt-right" data-label={t(currentLanguage+'_cc-saldo')}>{new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(cc.saldo))}</td>
                                                                        <td data-label={t(currentLanguage+'_cc-incl-doc')}>{cc.incluido_documento}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                            {totais.length > 0 &&(
                                                                <tr class="totaiscc">
                                                                    <th scope="row"></th>
                                                                    <td colSpan={3} data-label={t(currentLanguage+'_cc-total-periodo')} class="title">{t(currentLanguage+'_cc-total-periodo')}</td>
                                                                    <td class="dcf-txt-right" data-label={t(currentLanguage+'_cc-debito')}>{new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(totais[0].debito))}</td>
                                                                    <td class="dcf-txt-right" data-label={t(currentLanguage+'_cc-credito')}>{new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(totais[0].credito))}</td>
                                                                    <td class="dcf-txt-right" data-label={t(currentLanguage+'_cc-saldo')}>{new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(totais[0].saldo))}</td>
                                                                    {/* <td data-label={t(currentLanguage+'_cc-incl-doc')}></td> */}
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    ):(
                                                        <tbody>
                                                            {contaCorrentePendentes.length > 0 && contaCorrentePendentes.map((ccp, index) => {
                                                                return (
                                                                    <tr key={"contaCorrentePendentesRow"+index}>
                                                                        <th scope="row">{formatarData(ccp.data_doc)}</th>
                                                                        <td data-label={t(currentLanguage+'_cc-vencimento')}>{formatarData(ccp.data_venc)}</td>
                                                                        <td data-label={t(currentLanguage+'_cc-documento')}>{ccp.descricao_doc}</td>
                                                                        <td data-label={t(currentLanguage+'_cc-n-documento')}>{ccp.num_doc}</td>
                                                                        <td class="dcf-txt-right" data-label={t(currentLanguage+'_cc-idade')}>{ccp.idade_doc}</td>
                                                                        <td class="dcf-txt-right" data-label={t(currentLanguage+'_cc-valor')}>{new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(ccp.total_pendente))}</td>
                                                                        <td class="dcf-txt-right" data-label={t(currentLanguage+'_cc-saldo')}>{new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(ccp.saldo))}</td>
                                                                        <td data-label={t(currentLanguage+'_cc-incl-doc')}>{ccp.incluido_documento}</td>
                                                                    </tr>
                                                                )
                                                            })}
                                                            {/* {totaisPendentes.length > 0 &&(
                                                                <tr class="totaiscc">
                                                                    <th scope="row"></th>
                                                                    <td colSpan={3} data-label="Total" class="title">{t(currentLanguage+'_cc-total-periodo')}</td>
                                                                    <td class="dcf-txt-right" data-label="Débito">{new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(totaisPendentes[0].debito))}</td>
                                                                    <td class="dcf-txt-right" data-label="Crédito">{new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(totaisPendentes[0].credito))}</td>
                                                                    <td class="dcf-txt-right" data-label="Saldo">{new Intl.NumberFormat('pt-PT', { maximumFractionDigits: casasDecimais ,minimumFractionDigits: casasDecimais, style: 'currency', currency: 'EUR' }).format(parseFloat(totaisPendentes[0].saldo))}</td>
                                                                    <td data-label="Incl.Documento"></td>
                                                                </tr>
                                                            )} */}
                                                        </tbody>
                                                    )}
                                                </table>
                                                {(selectedCC === "cc" && contaCorrente.length === 0) || (selectedCC === "ccp" && contaCorrentePendentes.length === 0) &&(
                                                    <span>
                                                        {t(currentLanguage+"_cc-nada-pendente")}
                                                    </span>
                                                )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>                        
                            </div>                    
                        </main>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContaCorrente