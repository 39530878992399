import React, {useEffect, useState, useContext } from "react"
import { useTranslation } from 'react-i18next';
import { loginPHC } from "../../data/apiPHC";
const VITE_PUBLIC_ROOT = import.meta.env.VITE_PUBLIC_ROOT;
import { getPageAPI } from "../../data/tpagina";
import { getParameterByAliasAPI } from "../../data/parameter";
import { getMultimediaByAliasAndLanguage } from "../../data/multimedia";
import MetaPages from "../../components/MetaPages";
import Spinner from "../../components/Spinner";
import { AuthContext } from "../../contexts/authContext";
import { Link } from "react-router-dom";

import ReactGA from 'react-ga4';

const BaseImgPath = import.meta.env.VITE_BASE_IMAGE_PATH;

import './login.scss'

const Login = () => {
    const { t, i18n: {language} } = useTranslation();
    const [currentLanguage] = useState(language);
    const [page, setPage] = useState("")
    const thisUrlPage = window.location.href;
    const [nomeCliente, setNomeCliente] = useState("")

    const [dataLogin, setDataLogin] = useState({ username : "", password : "", remember : false})
    const [loadingLogin, setLoadingLogin] = useState(false)

    const {handleAuthContext} = useContext(AuthContext)

    const [img, setImg] = useState({})

    async function handleLogin(e){
        e.preventDefault()
        if(!loadingLogin){
            setLoadingLogin(true)
            try {
                await loginPHC(dataLogin)
                handleAuthContext()
                setLoadingLogin(false)
                window.location.replace(VITE_PUBLIC_ROOT+"/"+currentLanguage+"/dashboard")
            } catch (error) {
                setLoadingLogin(false)
            }
        }
        
    }

    async function getImage(){
        const response = await getMultimediaByAliasAndLanguage("fundo-login", currentLanguage)
        setImg(response)
    }

    async function getNomeCliente(){
        const response = await getParameterByAliasAPI("nomecliente")
        setNomeCliente(response.varchar)
    }
    async function getPage(){
        if(!page){
            const response = await getPageAPI("login", "login", currentLanguage)
            setPage(response)
        }
    }

    useEffect(() => {
        getNomeCliente()
        getPage()
        getImage()
    }, [])

    useEffect(() => {
        if(page){
            ReactGA.send({
                hitType: "pageview",
                page: thisUrlPage,
                title: page.pageTitle
            })
        }
    }, [page])

    return (
        <>  
            {page && nomeCliente &&(
                <MetaPages newLang={language} pageTitle={nomeCliente+" - "+page.pageTitle} pageMetaSummary={page.pageMetaSummary} pageMetaAuthor={page.pageMetaAuthor} pageMetaTags={page.pageMetaTags} robots="index, follow" ogtype="website" ogurl={thisUrlPage} pageSeoTitle={page.pageSeoTitle} pageSeoDescription={page.pageSeoDescription} pageSeoImage={page.pageSeoImage} pageSeoImageAlt={page.pageSeoImageAlt} />
            )}
            <section id="loginacesso">
                <div className="container-fluid">
                    <div className="row justify-content-center logincontent" style={{backgroundImage : 'url('+BaseImgPath+img.path+')', backgroundRepeat: "no-repeat", backgroundAttachment: "fixed", backgroundPosition: "center", backgroundSize: 'cover'}}>
                    {/* {image &&(
                        <div className="row justify-content-center img-top-content" style={{backgroundImage : 'url('+BaseImgPath+image.path+')'}}>
                            <div className="col-12 this-center-all">
                                <div className="wrapper-content ">
                                    <h2 className="display-5-b text-white">
                                        {page &&(
                                            page.pageTitle
                                        )}
                                    </h2>
                                </div>
                            </div>
                        </div>
                    )} */}
                        <div className="col-sm-12 col-md-6 col-lg-4 login-form-page">
                            <form onSubmit={(e) => handleLogin(e)} className="form-el form-with-styler" data-verified="">
                                <div className="row">
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <h1 className="fr-el-section-title mb-4">
                                            <strong>{t(currentLanguage+'_login')}</strong></h1>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <p className="fr-el-text fr-el-fonts-style mb-4">{t(currentLanguage+'_area-privada')}</p>
                                    </div>
                                    <div data-for="name" className="col-lg-12 col-md-12 col-sm-12 form-group mb-3">
                                        <input value={dataLogin.username} onChange={(e) => setDataLogin({...dataLogin, username : e.target.value})} type="text" name="name" placeholder={t(currentLanguage+'_nome-utilizador')} data-form-field="name" className="form-control"  id="name-form3-m"/>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 form-group mb-3" data-for="password">
                                        <input value={dataLogin.password} onChange={(e) => setDataLogin({...dataLogin, password : e.target.value})} type="password" name="password" placeholder={t(currentLanguage+'_password')} data-form-field="password" className="form-control"  id="password-form3-m"/>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mb-3 input-group">
                                        <input checked={dataLogin.remember} onChange={(e) => setDataLogin({...dataLogin, remember : e.target.checked})} type="checkbox" className="form-check-input" id="thislembrar"/>
                                        <label className="form-check-label" for="thislembrar"><span className="fr-el-text ">{t(currentLanguage+'_lembrar-me')}</span></label>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 mbr-section-btn">
                                        <button type="submit" className="btn btn-secondary btn-black">
                                            {loadingLogin ? (
                                                <Spinner/>
                                            ):(
                                                <span>{t(currentLanguage+'_aceder')}</span>
                                            )}
                                        </button>
                                    </div>
                                    <div className="col-lg-12 col-md-12 col-sm-12 footerform">
                                        <p className="fr-el-text ">
                                            {t(currentLanguage+'_nao-tem-acesso')}
                                            <Link to={`${VITE_PUBLIC_ROOT}/${currentLanguage}/registo`}>
                                                {t(currentLanguage+'_faca-o-seu-pedido-de-acesso')}
                                            </Link>
                                        </p>
                                        <p className="fr-el-text ">
                                            {t(currentLanguage+'_ficou-sem-acesso')}
                                            <Link to={`${VITE_PUBLIC_ROOT}/${currentLanguage}/recuperar`}>
                                                {t(currentLanguage+'_recuperar')}
                                            </Link>
                                        </p>
                                    </div>                         
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login